import React, { useEffect, useState } from "react";
import Link from "next/link";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";

import { TournamentModel } from "@models/TournamentModel";
import { formatCurrency } from "@app/utilities/helpers";
import SafeImage from "@components/common/SafeImage";
import SimpleCountdownTimerComp from "@components/common/SimpleCountdownTimerComp";

import codDefaultBanner from "@assets/images/tournaments/cod-tournament-banner-default.png";

import styles from "@css/components/tournaments/tournament_pane_comp.module.scss";

interface IFeatureBoxProps {
  data: TournamentModel;
  className?: string;
}

interface ITournamentPaneProps {
  data: TournamentModel;
  className?: string;
}

const FeatureBox: React.FC<IFeatureBoxProps> = ({ data: tournamentData, className: extClass }: IFeatureBoxProps) => {
  const classes = classNames(styles["feat-box"], extClass);

  return (
    <div className={classes}>
      <div className={classNames(styles["feat"], styles["feat-big"], styles["feat-highlight"])}>
        <i className={classNames("gff gff-t-prize", styles["feat-icon"], styles["feat-icon-lg"])}></i>
        <div className={styles["feat-wrapper"]}>
          <p className={styles["label"]}>Prize Pool</p>
          <p className={styles["value"]}>{tournamentData.prizePoolDisplay}</p>
        </div>
      </div>
      <div className={classNames(styles["feat-row"])}>
        <div className={styles["feat"]}>
          <i className={classNames("gff gff-t-size", styles["feat-icon"])}></i>
          <div className={styles["feat-wrapper"]}>
            <p className={styles["label"]}>Team Size</p>
            <p className={styles["value"]}>{tournamentData.mode}</p>
          </div>
        </div>
        <div className={styles["feat"]}>
          <i className={classNames("gff gff-t-fees", styles["feat-icon"])}></i>
          <div className={styles["feat-wrapper"]}>
            <p className={styles["label"]}>Entry Fee</p>
            <p className={styles["value"]}>{tournamentData.registrationFee ? <span>{formatCurrency(tournamentData.registrationFee, tournamentData.currency)}</span> : <span>Free</span>}</p>
          </div>
        </div>
      </div>
      <div className={styles["feat"]}>
        <i className={classNames("gff gff-game-mode", styles["feat-icon"])}></i>
        <div className={styles["feat-wrapper"]}>
          <p className={styles["label"]}>Mode</p>
          <p className={styles["value"]}>{tournamentData.gameModeDisplayName || "Kill Race"}</p>
        </div>
      </div>
    </div>
  );
};

const TournamentPane: React.FC<ITournamentPaneProps> = (props: ITournamentPaneProps): JSX.Element => {
  const { data: tournamentData, className: extClass } = props,
    classes = classNames(styles["tournament-pane-comp"], extClass),
    mStartDate = moment(tournamentData.startDate),
    startInSeconds = mStartDate.diff(moment(), "seconds"),
    dateFormatted = mStartDate.format("ddd, MMM DD"),
    timeFormatted = mStartDate.format("h:mm A"),
    /** GAMEF-582 Show registration timer if the tournament starts in 6 hours */
    [showRegistrationTimer, setShowRegistrationTimer] = useState(startInSeconds > 0 && startInSeconds <= 21600);

  useEffect(() => {
    let showTimer = 0,
      hideTimer = 0;

    if (!showRegistrationTimer && startInSeconds > 21600) {
      const timeoutToShow = _.clamp((startInSeconds - 21600) * 1e3, 1, 0x7fffffff),
        timeoutToHide = _.clamp(timeoutToShow + 21600e3, 1, 0x7fffffff);

      showTimer = window.setTimeout(() => {
        setShowRegistrationTimer(true);
      }, timeoutToShow);

      hideTimer = window.setTimeout(() => {
        setShowRegistrationTimer(true);
      }, timeoutToHide);
    }

    return () => {
      if (showTimer) {
        window.clearTimeout(showTimer);
      }

      if (hideTimer) {
        window.clearTimeout(hideTimer);
      }
    };
  }, [startInSeconds]);

  return (
    <div className={classes} data-testid="tournament-pane">
      <div className={styles["title"]}>{tournamentData.title}</div>
      <div className={styles["time-container"]}>
        <div className={styles["wrapper"]}>
          <span className={styles["label"]}>
            <i className="gff gff-calendar-alt mr-2"></i>
            <span className={styles["text"]}>Date:&nbsp;</span>
          </span>
          <span className={styles["value"]}>{dateFormatted}</span>
        </div>
        <div className={styles["wrapper"]}>
          <span className={styles["label"]}>
            <i className="gff gff-clock mr-2"></i>
            <span className={styles["text"]}>Start Time:&nbsp;</span>
          </span>
          <span className={styles["value"]}>{timeFormatted}</span>
        </div>
      </div>

      <div className={styles["img-container"]}>
        <figure>
          <SafeImage src={tournamentData.imageUrl} fallbackSrc={codDefaultBanner.src} loading="lazy" alt={tournamentData.title} />
          <figcaption>{tournamentData.title}</figcaption>
        </figure>
        {showRegistrationTimer && (
          <div className={styles["registration-timer"]}>
            <div className={styles["wrapper"]}>
              Registration Ending In -&nbsp;
              <SimpleCountdownTimerComp targetTimestamp={tournamentData.startDate} />
            </div>
          </div>
        )}
      </div>
      <FeatureBox data={tournamentData} className={styles["feature-box"]} />
      <Link href={tournamentData.url}>
        <a className={classNames("btn btn-primary darker", styles["btn-view"])} aria-label="View Tournament" aria-hidden="false">
          View Tournament
        </a>
      </Link>
    </div>
  );
};

export default TournamentPane;
