import React from "react";
import { DISCORD_INVITATION_LINK } from "@app/utilities";
const discord_logo_white_svg = "/static/images/brands/discord-logo-white.svg";

const DiscordMarkComp: React.FunctionComponent = (): JSX.Element => {
  return (
    <div id="discord-mark-comp">
      <a href={DISCORD_INVITATION_LINK} target="_blank" rel="noopener noreferrer">
        <img src={discord_logo_white_svg} className="discord-logo" alt="Warzone Tournaments Discord" />
      </a>
    </div>
  );
};

export default DiscordMarkComp;
