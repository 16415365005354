import React, { ImgHTMLAttributes } from "react";

interface ISafeImage extends ImgHTMLAttributes<HTMLImageElement> {
  fallbackSrc?: string;
}

const SafeImage: React.FC<ISafeImage> = (props: ISafeImage) => {
  const { fallbackSrc } = props,
    nativeProps = Object.assign({}, props),
    onImageError = (evt: React.SyntheticEvent<HTMLImageElement, Event>) => {
      const elem = evt.target as HTMLImageElement;

      if (fallbackSrc) {
        elem.onerror = null;
        elem.src = fallbackSrc;
      }
    };

  /**
   * Remove custom attributes at `nativeProps` for HTMLImageElement
   */
  delete nativeProps.fallbackSrc;

  return <img {...nativeProps} onError={onImageError} />;
};

export default SafeImage;
