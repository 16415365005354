import React from "react";
import { useSelector } from "react-redux";
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from "next";
import Link from "next/link";
import Head from "next/head";

import { userSelector } from "@ducks/user/selectors";

import DiscordMarkComp from "@components/common/DiscordMarkComp";

import { APP_URL, DISCORD_INVITATION_LINK, META_TITLE, META_URL, SOCIAL_FACEBOOK, SOCIAL_IG, SOCIAL_TWITTER } from "@app/utilities/constants";
import tournamentHttp from "@app/http/TournamentHttp";

import cod_warzone_logo from "@assets/images/brands/cod-warzone.png";
import discord_wordmark_logo from "@assets/images/brands/discord-logo-wordmark.png";
import pc_logo from "@assets/images/brands/windows.png";
import playstation_logo from "@assets/images/brands/playstation.png";
import xbox_logo from "@assets/images/brands/xbox.png";

import stats_frame from "@assets/images/landing_page/stats.png";
import tourney_frame from "@assets/images/landing_page/tourney.png";
import ui_tournament from "@assets/images/landing_page/ui-tournament.png";

import select_platform_svg from "@assets/images/svgs/select-platform.svg";
import discord_window_svg from "@assets/images/svgs/discord-window.svg";
import tickets_svg from "@assets/images/svgs/tickets.svg";
import TournamentPane from "@components/tournaments/TournamentPaneComp";

const OrganizationSchema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "GameFace",
  url: APP_URL,
  logo: [APP_URL, "static/images/logo-gamefaced.png"].join("/"),
  sameAs: [SOCIAL_FACEBOOK, SOCIAL_IG, SOCIAL_TWITTER],
};

const LandingPage: NextPage = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { ssrUpcomingTournaments } = props,
    { data: userData } = useSelector(userSelector),
    isAuthenticated = !!userData;

  /**
   * Meta contents
   */
  const metaUrl = META_URL || "https://gamefaced.gg";

  return (
    <div id="landing-page" className="" data-testid="landing-page">
      <Head>
        <title>{META_TITLE}</title>
        <link rel="canonical" href={metaUrl} />
        <script
          key={"organization"}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(OrganizationSchema),
          }}
        ></script>
        {ssrUpcomingTournaments &&
          ssrUpcomingTournaments.length &&
          ssrUpcomingTournaments.map((upcomingTournament) => (
            <script
              key={"tjson_" + upcomingTournament.id}
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(upcomingTournament.schema),
              }}
            ></script>
          ))}
      </Head>
      <DiscordMarkComp />
      <section className="intro position-relative">
        <div className="bg-overlays">
          <div className="bg bg-hero"></div>
          <div className="bg bg-foregrounds d-none d-lg-block"></div>
          <div className="bg bg-hexagons"></div>
        </div>
        <div className="hero container text-center text-md-left">
          <div className="wrapper xl">
            <h1 className="tagline" data-testid="tagline">
              Call of Duty: Warzone tournaments
            </h1>
            <p className="title">
              <span className="d-block mb-2 mb-lg-3">Epic Play.</span>
              <span className="d-block mb-2 mb-lg-3">Cash Prizes.</span>
              <span className="d-block ">Electric Community.</span>
            </p>
          </div>
          <div className="wrapper">
            <h2 className="description d-block mb-6">
              Squad up with your friends, grab your loadouts, and compete for cash prizes! Our skill-based Warzone tournaments are furious, fast, and fun, with top rewards for all divisions. Ready to show your gameface?
            </h2>
          </div>
          <div className="actions-container">
            <div className="actions position-relative">
              <Link href="/warzone/tournaments">
                <a className="btn btn-primary glow-primary text-uppercase font-weight-bold browse anim-scale-up">{isAuthenticated ? "Join Tournament" : "Sign Up Now"}</a>
              </Link>
            </div>
            <div className="actions position-relative">
              <a href={DISCORD_INVITATION_LINK} target="_blank" className="btn btn-discord glow-blurple text-uppercase font-weight-bold browse anim-scale-up" rel="noreferrer noopener">
                <i className="fab fa-discord mr-2"></i>
                Join Us On Discord
              </a>
            </div>
          </div>
          <div className="wrapper xl o0o0">
            <div className="img-wrapper w-100 d-flex flex-column flex-md-row justify-content-around align-items-center">
              <img className="logo" loading="lazy" src={cod_warzone_logo.src} alt="COD Warzone" width={132} height={44} />
              <img className="logo" loading="lazy" src={discord_wordmark_logo.src} alt="Discord" width={190} height={44} />
              <div className="hlogo-wrapper">
                <img className="logo" loading="lazy" src={pc_logo.src} alt="Windows" width={48} height={44} />
                <img className="logo" loading="lazy" src={playstation_logo.src} alt="Playstation" width={57} height={44} />
                <img className="logo" loading="lazy" src={xbox_logo.src} alt="XBox" width={49} height={44} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {ssrUpcomingTournaments && ssrUpcomingTournaments.length && (
        <section className="upcoming-tournament" data-testid="upcoming-tournament">
          <h2 className="text-uppercase title text-center mb-5 mt-10">UPCOMING TOURNAMENTS</h2>
          <div className="upcoming-tournament-container">
            {ssrUpcomingTournaments.map((upcomingTournament, idx) => (
              <TournamentPane key={idx} data={upcomingTournament} className={"upcoming-tournament-card"} />
            ))}
          </div>
          <div className="actions position-relative">
            <Link href="/tournaments">
              <a className="btn btn-secondary text-uppercase font-weight-bold browse anim-scale-up">View more</a>
            </Link>
          </div>
        </section>
      )}
      <section className="section-container bg-how-it-works">
        <section className="how py-10 py-x2l-12">
          <div className="container text-center">
            <p className="text-uppercase font-weight-bold lead">How It Works</p>

            <h2 className="text-uppercase title">Join in 3 easy steps</h2>

            <div className="banner-list d-flex flex-column flex-md-row align-items-start justify-content-between">
              <div className="banner bg-gradient-1">
                <div className="badge">
                  <img className="img" loading="lazy" src={discord_window_svg.src} alt="Discord" width={62} height={62} />
                </div>
                <p className="tag">01</p>
                <p className="title">Sign up with Discord</p>
                <p className="description">No need to create a separate account. Your Discord account is also your GameFace account! Signing up has never been this easy.</p>
              </div>
              <div className="banner bg-gradient-1">
                <div className="badge bigger">
                  <img className="img" loading="lazy" src={select_platform_svg.src} alt="Platform" width={82} height={69} />
                </div>
                <p className="tag">02</p>
                <p className="title">Select your platform</p>

                <p className="description">Whether you are on PC, PlayStation, or Xbox, simply enter your platform ID and we will connect it to your Discord account.</p>
              </div>
              <div className="banner bg-gradient-1">
                <div className="badge">
                  <img className="img" loading="lazy" src={tickets_svg.src} alt="Tickets" width={62} height={62} />
                </div>
                <p className="tag">03</p>
                <p className="title">Buy a ticket</p>
                <p className="description">Pay securely for your ticket through PayPal. Once purchased, easily create and check in your team with our custom Discord bot!</p>
              </div>
            </div>
          </div>
        </section>

        <section className="frames mb-5 mb-x2l-6">
          <div className="container">
            <div className="row frame align-items-center flex-column flex-lg-row">
              <div className="img-container col flex-grow-1 d-none d-lg-flex">
                <img className="img-frame" loading="lazy" src={stats_frame.src} alt="Stats" width={605} height={478} />
              </div>
              <div className="col flex-grow-1 text-center text-lg-left">
                <p className="text-uppercase font-weight-bold lead">Automated skill divisions</p>

                <img className="img-frame mobile d-lg-none my-4" src={stats_frame.src} alt="Stats" width={605} height={478} />

                <h2 className="text-uppercase title">Play at your own level</h2>
                <p className="description">
                  Enjoy better games and stand a higher chance of winning by competing with friends at your skill level. We group players with similar kill death ratios – games are more exciting and anyone could be on top!
                </p>
              </div>
            </div>
            <div className="row frame align-items-center flex-column flex-lg-row flex-lg-row-reverse">
              <div className="img-container col flex-grow-1 d-none d-lg-flex">
                <img className="img-frame" loading="lazy" src={tourney_frame.src} alt="Tournament" width={605} height={458} />
              </div>
              <div className="col flex-grow-1 text-center text-lg-right">
                <p className="text-uppercase font-weight-bold lead">Community Driven</p>

                <img className="img-frame mobile d-lg-none my-4" src={tourney_frame.src} alt="Tournament" width={605} height={458} />

                <h2 className="text-uppercase title">Party up and earn rewards</h2>
                <p className="description">
                  Whether you play solo or are looking for new friends, our community offers it all. Join in on the most dynamic{" "}
                  <a href="https://www.callofdutydiscord.com/" target="_blank" rel="noreferrer noopener">
                    Call of Duty: Warzone community
                  </a>{" "}
                  out there and earn rewards for playing in events, engaging with fellow members, and encouraging friends to show their gameface!
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <section className="scoring m-0 py-5 py-x2l-6">
        <div className="container text-center">
          <p className="text-uppercase font-weight-bold lead">Live Scoreboard</p>

          <h2 className="text-uppercase title">No more bullshit</h2>
          <p className="description">
            GameFace automatically calculates your best games by combining your kills and match placement in real time.
            <br />
            Never settle for photo submissions and manual forms again. Focus on playing with your friends and winning those games!
          </p>

          <div className="actions position-relative">
            <Link href="/warzone/tournaments">
              <a className="btn btn-primary glow-primary text-uppercase font-weight-bold browse anim-scale-up">{isAuthenticated ? "Join Tournament" : "Sign Up Now"}</a>
            </Link>
          </div>

          <div className="img-wrapper position-relative mx-auto w-100">
            <img className="img" loading="lazy" src={ui_tournament.src} alt="Tournament" width={232} height={161} />
          </div>
        </div>
      </section>

      <section className="join-now bg-join-now m-0 py-10 py-x2l-12">
        <div className="container text-center">
          <p className="text-uppercase font-weight-bold lead">Ready to play?</p>

          <h2 className="text-uppercase title">Show us your gameface!</h2>

          <div className="actions position-relative">
            <Link href="/warzone/tournaments">
              <a className="btn btn-primary glow-primary text-uppercase font-weight-bold browse anim-scale-up">{isAuthenticated ? "Join Tournament" : "Sign Up Now"}</a>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const nextTournaments = await tournamentHttp.searchTournaments({
    page: 0,
    pageSize: 3,
    upcoming: true,
  });

  if (nextTournaments && nextTournaments.totalCount > 0) {
    return {
      props: {
        ssrUpcomingTournaments: nextTournaments.data,
      },
    };
  }

  return {
    props: {
      ssrUpcomingTournaments: null,
    },
  };
};

export default LandingPage;
