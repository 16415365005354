import { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { isBot } from "@app/utilities";

type ICountdownTime = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type ReadableCountdownTime = {
  digits: string;
  label: string;
};

const getCountdownTime = (targetTimestamp: number): ICountdownTime => {
  const duration = moment.duration(targetTimestamp - Date.now());

  return {
    days: _.clamp(duration.get("days"), 0, 99),
    hours: _.clamp(duration.get("hours"), 0, 23),
    minutes: _.clamp(duration.get("minutes"), 0, 59),
    seconds: _.clamp(duration.get("seconds"), 0, 59),
  } as ICountdownTime;
};

/**
 * Returns the converted value of ICountdownTime to be used in Timer interface
 * @param countdownTime ICountdownTime model
 * @returns An array with a fixed length of 4 containing digits and label, in the following order "Days", "Hours", "Minutes" and "Seconds"
 */
export const toReadableDigits = (countdownTime: ICountdownTime): ReadableCountdownTime[] => {
  return [
    {
      digits: countdownTime.days.toString().padStart(2, "0"),
      label: "Days",
    },
    {
      digits: countdownTime.hours.toString().padStart(2, "0"),
      label: "Hours",
    },
    {
      digits: countdownTime.minutes.toString().padStart(2, "0"),
      label: "Minutes",
    },
    {
      digits: countdownTime.seconds.toString().padStart(2, "0"),
      label: "Seconds",
    },
  ];
};

/**
 * A hook to keep track of countdown timer.
 * @param targetMs The future timestamp in millis
 * @param updateIntervalMs The update frequency in millis
 * @returns ICountdownTime
 */
export default function useCountdownTimer(targetMs: number, updateIntervalMs?: number): ICountdownTime {
  const initialCountdownTime = getCountdownTime(targetMs),
    [countdownTime, setCountdownTime] = useState(initialCountdownTime);

  useEffect(() => {
    let timer = 0;
    const currentMillis = Date.now(),
      cdTime = getCountdownTime(targetMs);

    if (!updateIntervalMs) {
      /**
       * Automated update interval
       * If countdownTime is less than 24h
       * Set the update interval to every second, otherwise every minute
       */
      updateIntervalMs = cdTime.days === 0 ? 1e3 : 6e4;
    }

    if (currentMillis < targetMs && !isBot()) {
      timer = window.setInterval(() => {
        setCountdownTime(getCountdownTime(targetMs));
      }, updateIntervalMs);
    }

    setCountdownTime(cdTime);

    return () => {
      if (timer) {
        window.clearInterval(timer);
      }
    };
  }, [targetMs]);

  return countdownTime;
}
