import React from "react";
import useCountdownTimer, { toReadableDigits } from "@app/hooks/useCountdownTimer";
interface ISimpleCountdownTimerCompProps {
  targetTimestamp: number;
  onTimerOff?: () => void;
}

const SimpleCountdownTimerComp: React.FC<ISimpleCountdownTimerCompProps> = ({ targetTimestamp }: ISimpleCountdownTimerCompProps): JSX.Element => {
  const countdownTime = useCountdownTimer(targetTimestamp),
    readableDigits = toReadableDigits(countdownTime);

  if (readableDigits.length === 4) {
    if (countdownTime.days === 0) {
      readableDigits.shift();
    } else {
      readableDigits.pop();
    }
  }

  const textDigit = readableDigits
    .map((readableDigit) => {
      return `${readableDigit.digits || "00"}${readableDigit.label[0].toLowerCase()}`;
    })
    .join(" ");

  return (
    <span data-testid="simple-countdown-timer-comp" suppressHydrationWarning>
      {textDigit}
    </span>
  );
};

export default SimpleCountdownTimerComp;
